import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Styles
import styles from "./services.module.sass"

// Icons
// import ArrowRight from "../../../icons/arrow-right.svg"
import DiscoveryIcon from "../../../icons/services/discovery.svg"
import RAndDIcon from "../../../icons/services/r-and-d-and-technology-innovation.svg"
import BlockchaineIcon from "../../../icons/services/blockchain-and-infrastructure-solutions.svg"
import SoftwareIcon from "../../../icons/services/software-engineering.svg"
import HardwareIcon from "../../../icons/services/hardware-engineering.svg"
import ConsultingIcon from "../../../icons/services/technology-consulting.svg"
import DevelopingIcon from "../../../icons/services/developing.svg"
import DesignIcon from "../../../icons/services/design.svg"

const services = [
  {
    icon: <RAndDIcon />,
    title: `R&D and technology innovation`,
    content: `We assemble R&D teams for our clients to co-build Blockchain products.`,
  },
  {
    icon: <BlockchaineIcon />,
    title: `Blockchain and Infrastructure solutions`,
    content: `Madfish can help your business build standard/full custom blockchain solutions or create and endorse infrastructure like wallets, explorers, etc.`,
  },
  {
    icon: <SoftwareIcon />,
    title: `Software engineering`,
    content: `High-quality software solutions that will bring you tangible results. Let’s talk about your goal.`,
  },
  {
    icon: <HardwareIcon />,
    title: `Hardware Engineering`,
    content: `Engaging our qualified partners we can help unite the software and hardware solutions into synergy. FPGA, Servers cooling systems, energy storage - everything that you want.`,
  },
  {
    icon: <ConsultingIcon />,
    title: `Technology consulting`,
    content: `Are you looking for someone to help with innovations in your bussines? Benefit from 3 years of technical excellence and let us be your secret weapon when it comes to innovative technologies.`,
  },
  {
    icon: <DevelopingIcon />,
    title: `PoC Development`,
    content: `Safely explore business-boosting concepts before taking them to market – with robust testing, project predictions and expert roadmapping.`,
  },
  {
    icon: <DesignIcon />,
    title: `Super Care`,
    content: `Flawlessly manage technical issues and periods of systems change while keeping your core team focused on the project, with advanced IT support and maintenance services.`,
  },
]

const ServicesScreen = ({ page = false }) => {
  // Equal height with resize detections
  function debounce(fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    }
  }
  const [height, setHeight] = useState("auto")
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      let maxHeight = 0
      const servicesCardsContent = document.querySelectorAll(
        `.${styles.service} .${styles.content}`
      )
      servicesCardsContent.forEach(el => {
        if (el.offsetHeight > maxHeight) maxHeight = el.offsetHeight
      })
      if (window.innerWidth > 768) {
        if (maxHeight !== height) setHeight(maxHeight)
      } else {
        setHeight("auto")
      }
    }, 1000)
    debouncedHandleResize()

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [])

  return (
    <section className={"section " + page ? styles.services : ""}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className="heading">
            <h2>Services</h2>
            <p>
              We provide a full range of IT services with different levels of
              customer involvement
            </p>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.service + ` ` + styles.serviceDiscovery}>
              <div style={{ height: height }}>
                <Link to="/" className={styles.link}></Link>
                <div className={styles.content}>
                  <DiscoveryIcon />
                  <div className={styles.info}>
                    <h3>Discovery</h3>
                    <p>
                      Discovery phase is where you undertake all the research
                      you need in order to fully scope and plan your entire
                      digital project. The discovery phase aims to gain a
                      detailed understanding of stakeholders’ and the end
                      customers’ vision in order to come up with a better
                      performing product.
                    </p>
                    {/*<button className="button button-arrow white">*/}
                    {/*  view more <ArrowRight />*/}
                    {/*</button>*/}
                  </div>
                </div>
              </div>
            </div>
            {services.map(el => (
              <div className={styles.service} key={el.title}>
                <StyledCard flexContent hovered>
                  <div
                    className={styles.cardWrapper}
                    style={{ height: height }}
                  >
                    <div className={styles.content}>
                      <div className={styles.icon}>{el.icon}</div>
                      <div className={styles.info}>
                        <h3>{el.title}</h3>
                        <p>{el.content}</p>
                        {/*<button className="button button-arrow">*/}
                        {/*  view more <ArrowRight />*/}
                        {/*</button>*/}
                      </div>
                    </div>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesScreen
